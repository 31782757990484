<footer class="footer">
  <div class="footer-container vpy-6 px-2 text-center">

    <img class="mt-3 logo" src="assets/img/logo/Qatch-Logo.png" />

    <div class="social-accounts-links d-flex justify-content-center align-items-center mt-5">
      <a class="{{sAccount.tag}} {{sAccount.icon !== 'tiktok'? 'p-1 mx-1':''}} d-flex"
        *ngFor="let sAccount of socialAccountsLinks;" [title]="sAccount.title" [href]="sAccount.href" target="_blank">
        <i *ngIf="sAccount.icon !== 'tiktok'" class="pi pi-{{sAccount.icon}} my-auto"></i>
        <div *ngIf="sAccount.icon==='tiktok'">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="27" viewBox="14 12 44 46"
            style="fill:#FFFFFF;">
            <path
              d="M42.5,14C56.875,14,58,15.125,58,29.5v13C58,56.875,56.875,58,42.5,58h-13C15.125,58,14,56.875,14,42.5v-13	C14,15.125,15.125,14,29.5,14H42.5z M45.616,34.045v-3.79c0,0-2.003,0.01-3.695-1.54c-1.422-1.302-1.54-3.743-1.54-3.743h-3.79	c0,0,0,12.934,0,14.994s-1.793,3.127-3.079,3.127c-0.969,0-3.174-0.76-3.174-3.151c0-2.51,2.535-3.15,3.198-3.15	c0.663,0,0.924,0.142,0.924,0.142v-4c0,0-0.616-0.059-1.066-0.074c-3.825-0.13-6.988,3.303-6.988,7.083	c0,3.193,2.497,7.059,7.059,7.059c4.859,0,7.083-4.052,7.083-7.035c0-2.179,0-7.438,0-7.438s1.398,0.782,2.535,1.137	C44.219,34.021,45.616,34.045,45.616,34.045z">
            </path>
          </svg>
        </div>
      </a>
    </div>

    <div class="qatch-pages-links d-flex justify-content-between my-3 p-1 border-left-0 border-right-0">
      <a class="py-2 mx-1 {{qatchPage.tag}}" *ngFor="let qatchPage of qatchPagesLinks;" [routerLink]="qatchPage.path"
        [queryParamsHandling]="'merge'" [innerText]="qatchPage.title" [href]="qatchPage.href"></a>
      <a class="py-2 mx-1 {{outerPage.tag}}" *ngFor="let outerPage of outerPagesLinks;" [innerText]="outerPage.title" [href]="outerPage.href"></a>
    </div>

    <div class="copyright-info">
      Copyright © {{currentYear}} Qatchco Inc. By using this site or any part of Qatchco, you’re agreeing to our
      <a class="home_privacy_policy_footer" [routerLink]="['/privacy-policy']" [queryParamsHandling]="'merge'">Privacy
        Policy</a> and
      <a class="homer_terms_service_footer" [routerLink]="['/terms']" [queryParamsHandling]="'merge'">Terms of
        Service</a>.
      <!-- {{test | date: 'yyyy'}} -->
    </div>
  </div>
</footer>